import React, { useEffect, useMemo, useState } from 'react'
import {
  MDCTimePeriod,
  TimePeriodToHighlightInterface,
} from 'modules/asset/assetCrud/meterDataCleansing/MeterDataCleansingChart'
import MeterDataCleansingFormBody from 'modules/asset/assetCrud/meterDataCleansing/form/MeterDataCleansingFormBody'
import { FormApi } from 'final-form'
import { AnyObject, useField, useFormState } from 'react-final-form'
import { Asset } from 'modules/asset/store/asset.types'
import {
  MeterDataCleansingConfiguration,
  MeterDataCleansingFilterSettings,
} from 'modules/asset/assetCrud/meterDataCleansing/meterDataCleansingTypes'
import { QUERY_ACTIVE_TAB, QUERY_ASSET } from 'utils/query-string'
import FormBlockNavigation from 'ui/FormBlockNavigation'
import { c, t } from 'ttag'
import { checkIfMeterDataCleansingConfigChanged } from 'utils/meterDataCleansing'
import { Timezone } from 'fixtures/timezones'
import MeterDataCleansingChartWrapper from 'modules/asset/assetCrud/meterDataCleansing/MeterDataCleansingChartWrapper'
import { assetTabNames } from 'fixtures/assetForm'
import LoadingButton from 'ui/form/LoadingButton'
import { UseMutateFunction } from 'react-query'
import { Error } from 'utils/request'
import SectionHeader from 'modules/asset/assetCrud/assetDetails/SectionHeader'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ConditionalWrapper from 'ui/utility/ConditionalWrapper'
import { MDC_REQUEST_FAILED } from 'modules/asset/assetCrud/meterDataCleansing/MeterDataCleansingDetails'
import PopperTooltip, { PopperTooltipPosition } from 'ui/PopperTooltip'
import { theme } from 'themes/theme-light'
import styled from 'styled-components'
import { useIsReadOnlyUser } from 'utils/user'

const FormContainer = styled.div`
  & .MuiFormHelperText-root {
    position: absolute;
    bottom: -16px;
  }
`

interface MeterDataCleansingFormProps {
  initialMeterDataCleansingConfig: MeterDataCleansingConfiguration
  asset: Asset
  cleansingData: any
  defaultFilterSettings?: MeterDataCleansingFilterSettings
  form: FormApi
  timePeriodToExcludeFromChart: TimePeriodToHighlightInterface[]
  timePeriodsToExcludedFromTraining: TimePeriodToHighlightInterface[]
  mdcRequestFailed: MDC_REQUEST_FAILED | null
  onChangeTimePeriod: (timePeriod: TimePeriodToHighlightInterface) => void
  onChangeTimePeriodForTraining: (timePeriod: MDCTimePeriod) => void
  onFormSubmit: (
    event?: Partial<Pick<React.SyntheticEvent, 'preventDefault' | 'stopPropagation'>>,
  ) => Promise<AnyObject | undefined> | undefined
  onSetRefreshDataValue: (value: boolean) => void
  onSetFormChangedExternally: (value: boolean) => void
  trainingStarted: boolean
  timePeriodToEdit: TimePeriodToHighlightInterface | null
  onEditExcludedTimePeriod: (timePeriod: TimePeriodToHighlightInterface) => void
  onCancelEditExcludedTimePeriod: () => void
  onSaveExcludedTimePeriod: (timePeriod: TimePeriodToHighlightInterface) => void
  onDeleteExcludedTimePeriod: (timePeriod: TimePeriodToHighlightInterface) => void
  onDeleteAllExcludedTimePeriods: () => void
  refreshData: boolean
  onRefresh: () => void
  totalTimePeriod: MDCTimePeriod
  loadingCleansingData: boolean
  userTimezone: Timezone
  formChangedExternally: boolean
  onStartTraining: () => void
  saveResult: UseMutateFunction<MeterDataCleansingConfiguration, Error, MeterDataCleansingConfiguration, any>
  excludedTimePeriodFromChartIsInvalid: boolean
  linkToDefault: boolean
  onLinkToDefaultChange: () => void
}

const MeterDataCleansingForm: React.FC<MeterDataCleansingFormProps> = ({
  initialMeterDataCleansingConfig,
  asset,
  cleansingData,
  defaultFilterSettings,
  form,
  timePeriodToExcludeFromChart,
  timePeriodsToExcludedFromTraining,
  mdcRequestFailed,
  onChangeTimePeriod,
  onChangeTimePeriodForTraining,
  onFormSubmit,
  onSetRefreshDataValue,
  onSetFormChangedExternally,
  trainingStarted,
  timePeriodToEdit,
  onEditExcludedTimePeriod,
  onCancelEditExcludedTimePeriod,
  onSaveExcludedTimePeriod,
  onDeleteExcludedTimePeriod,
  onDeleteAllExcludedTimePeriods,
  refreshData,
  onRefresh,
  totalTimePeriod,
  loadingCleansingData,
  userTimezone,
  formChangedExternally,
  onStartTraining,
  saveResult,
  excludedTimePeriodFromChartIsInvalid,
  linkToDefault,
  onLinkToDefaultChange,
}) => {
  const timePeriodForTraining = useField<MDCTimePeriod>('timePeriodForTraining').input.value
  const [formValuesAfterRefresh, setFormValuesAfterRefresh] = useState<MeterDataCleansingConfiguration | null>(null)
  const startTrainingFailed = mdcRequestFailed === MDC_REQUEST_FAILED.START_TRAINING
  const isReadOnlyUser = useIsReadOnlyUser()

  const formInvalid = useMemo(() => {
    return form.getState().invalid || excludedTimePeriodFromChartIsInvalid
  }, [form.getState().invalid, excludedTimePeriodFromChartIsInvalid])

  const formState = useFormState()

  const formValuesCurrent = formState.values as MeterDataCleansingConfiguration

  const formInitialValues = useMemo(() => {
    return initialMeterDataCleansingConfig
  }, [initialMeterDataCleansingConfig])

  const handleResetFormChangedExternally = () => {
    onSetFormChangedExternally(false)
    onSetRefreshDataValue(false)
  }

  useEffect(() => {
    if (Object.keys(timePeriodForTraining).length > 0) {
      onChangeTimePeriodForTraining(timePeriodForTraining)
    }
  }, [JSON.stringify(timePeriodForTraining)])

  // We set excluded time periods into the form state as they are coming externally from the chart above the form
  // Whenever there is a change in the time periods we set formChangeExternally flag to true to handle unsaved changes dialog
  useEffect(() => {
    form?.mutators.setValue('excludedTimePeriods', timePeriodToExcludeFromChart)
  }, [timePeriodToExcludeFromChart])

  // Enable refresh button in the chart if there is change in the form
  useEffect(() => {
    // Initially formValuesAfterRefresh might be null so we use formInitialValues
    const valuesAfterRefresh = formValuesAfterRefresh || formInitialValues
    const configChanged = checkIfMeterDataCleansingConfigChanged(formValuesCurrent, valuesAfterRefresh)
    if (configChanged) {
      onSetRefreshDataValue(true)
      setFormValuesAfterRefresh(formValuesCurrent)
    }
  }, [formValuesCurrent, formValuesAfterRefresh, formInitialValues])

  const actions = useMemo(() => {
    return (
      <ConditionalWrapper
        condition={startTrainingFailed}
        wrapper={(children) => (
          <PopperTooltip
            popperLabel={children}
            popperContent={<div>{c('Meter data cleansing').t`Could not start training.`}</div>}
            position={PopperTooltipPosition.BOTTOM_START}
          />
        )}
      >
        <LoadingButton
          disabled={isReadOnlyUser || refreshData || formInvalid}
          onClick={() => onStartTraining()}
          color="primary"
          size="small"
          variant="contained"
          loading={trainingStarted}
          style={{ cursor: startTrainingFailed ? 'help' : 'pointer' }}
          startIcon={
            <FontAwesomeIcon
              color={startTrainingFailed ? theme.palette.error.main : 'inherit'}
              icon={startTrainingFailed ? 'exclamation-triangle' : 'play-circle'}
              fixedWidth
            />
          }
        >
          {t`Start training`}
        </LoadingButton>
      </ConditionalWrapper>
    )
  }, [startTrainingFailed, isReadOnlyUser, refreshData, formInvalid, trainingStarted, onStartTraining])

  return (
    <FormContainer>
      <FormBlockNavigation
        blockWithExternalChanges={formChangedExternally}
        navigationDialogText={c('Asset Details').t`Meter data cleansing`}
        form={form}
        currentPageQueries={[QUERY_ASSET, QUERY_ACTIVE_TAB]}
        navigationDialogKey={'meterDataCleansing'}
        onResetExternalChanges={handleResetFormChangedExternally}
      />
      <form onSubmit={onFormSubmit} noValidate>
        <SectionHeader
          form={form}
          activeTab={assetTabNames.meterDataCleansing}
          isNew={false}
          formChanged={form.getState().dirty || formChangedExternally}
          loading={loadingCleansingData}
          asset={asset}
          saveResult={saveResult}
          onFormSubmit={onFormSubmit}
          enterManually={false}
          extraActions={actions}
          disableTitleInputField={true}
          disableSaveExternally={refreshData || formInvalid}
        />

        <MeterDataCleansingChartWrapper
          onAddNewTimePeriodToExclude={onChangeTimePeriod}
          timePeriodsToExcludeFromChart={timePeriodToExcludeFromChart}
          asset={asset}
          timezone={userTimezone}
          onEditExcludedTimePeriod={onEditExcludedTimePeriod}
          totalTimePeriod={totalTimePeriod}
          timePeriodExcludedFromTraining={timePeriodsToExcludedFromTraining}
          linkToDefault={linkToDefault}
        />
        <MeterDataCleansingFormBody
          asset={asset}
          defaultFilterSettings={defaultFilterSettings}
          mdcRequestFailed={mdcRequestFailed}
          form={form}
          formInvalid={formInvalid}
          totalTimePeriod={totalTimePeriod}
          timePeriodToExcludeFromChart={timePeriodToExcludeFromChart}
          onChangeTimePeriod={onChangeTimePeriod}
          refreshData={refreshData}
          onRefresh={onRefresh}
          cleansingData={cleansingData}
          timePeriodToEdit={timePeriodToEdit}
          onSetRefreshDataValue={onSetRefreshDataValue}
          onEditExcludedTimePeriod={onEditExcludedTimePeriod}
          onCancelEditExcludedTimePeriod={onCancelEditExcludedTimePeriod}
          onSaveExcludedTimePeriod={onSaveExcludedTimePeriod}
          onDeleteExcludedTimePeriod={onDeleteExcludedTimePeriod}
          onDeleteAllExcludedTimePeriods={onDeleteAllExcludedTimePeriods}
          loadingCleansingData={loadingCleansingData}
          userTimezone={userTimezone}
          linkToDefault={linkToDefault}
          onLinkToDefaultChange={onLinkToDefaultChange}
        />
      </form>
    </FormContainer>
  )
}

export default React.memo(MeterDataCleansingForm)
